import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Landing from "./components/Landing";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Venue from "./components/Venue";
import Speakers from "./components/Speakers";
// import Agenda from "./components/Agenda";
import Surveys from "./components/Surveys";
import Register from "./components/Register";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import { GetAllSpeakers } from "./services/apiService";
import Confirmation from "./components/Confirmation";
import { ConferenceSpeakers, ConferenceAgenda } from "./services/testApiData";
import Booking from "./components/Booking";
import PasswordReset from "./components/PasswordReset";
import Resources from "./components/Resources";

function App() {
  const [speakerData, setSpeakers] = useState(ConferenceSpeakers);
  const [agendaData, setAgenda] = useState(ConferenceAgenda);
  useEffect(() => {
    // const fetchSpeakers = async () => {
    //   const speakers = await GetAllSpeakers(12); //Change this number to 16 for Summit data
    //   let speakerInfo = speakers.Data.map(
    //     ({
    //       AvatarUrl,
    //       Name,
    //       Bio,
    //       Title,
    //     }: Presenters) => ({ AvatarUrl, Name, Bio, Title })
    //   );
    //   setSpeakers(speakerInfo);
    // };
    // fetchSpeakers();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop />
        <Routes>
          <Route path="/Home" element={<Landing Speakers={speakerData} Sessions={agendaData}/>} />
          <Route path="/Venue" element={<Venue />} />
          <Route path="/Speakers" element={<Speakers Speakers={speakerData}/>} />
          {/* <Route path="/Agenda" element={<Agenda Sessions={agendaData} />} /> */}
          <Route path="/Booking" element={<Booking />} />
          <Route path="/Surveys" element={<Surveys />} />
          {/* <Route path="/Register" element={<Register />} /> */}
          <Route path="/PasswordReset/:guid" element={<PasswordReset />} />
          <Route path="/Confirmation" element={<Confirmation />}/>
          <Route path="/Resources" element={<Resources />} />
          <Route path="*" element={<Navigate to="/Home" />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
