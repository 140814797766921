import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import ConfLogo from "../assets/Logo.png";

type Props = {};

const ConfNavbar: React.FC = (props: Props): JSX.Element => {
  return (
    <Navbar collapseOnSelect expand="lg" className="confNav" variant="dark">
      <Container className="confNavbar my-3">
        <Navbar.Brand className="logoBox">
          <Link to="/">
            <img
              src={ConfLogo}
              className="confLogo"
              alt="Captain Annual Summit Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Link to="/Speakers" className="navText mx-3 nav-link">
              SPEAKERS
            </Link>
            <Link to="/Venue" className="navText mx-3 nav-link">
              VENUE
            </Link>
            <Link to="/Booking" className="navText mx-3 nav-link">
              BOOKING
            </Link>
            <Link to="/Resources" className="navText mx-3 nav-link">
              RESOURCES
            </Link>
            {/* <Link to="/Agenda" className="navText mx-3 nav-link">
              AGENDA
            </Link> */}
            <Link to="/Surveys" className="navText mx-3 nav-link">
              SURVEYS
            </Link>
            {/* <Link to="/Register" className="navText mx-3 nav-link">
              REGISTER
            </Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default ConfNavbar;
